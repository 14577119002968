.blog-container {
  display: grid;
  grid-template-rows: 10vh 90vh;
  width: 100%;
}
.style-option {
  font-weight: 500;
  text-align: center;
}
.info-bar {
  height: 100%;
  width: 100%;
  background-color: #146396;
  color: white;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.2);
}
.info-bar .MuiButton-label {
  color: white;
}
.MuiButton-root:hover {
  margin-bottom: 1px;
}

.blog-display {
  height: 100%;
  width: 100%;
  overflow-y: scroll;
}
.blog-flex {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}
.blog-display::-webkit-scrollbar {
  display: none;
}

.button-flex {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.blog-view {
  display: grid;
}
@media screen and (max-width: 600px) {
  .blog-inner {
    display: grid;
    grid-template-rows: 15vh 50vh;
    width: 100%;
    margin-bottom: 2vh;
  }
  .blog-top {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .blog-bottom {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
  }
  .blog-image {
    max-width: 100%;
    height: 75%;
  }
  .blog-title {
    align-self: center;
  }
  .article-view > * {
    margin-top: 1vh;
  }
  .login-view {
    display: flex;
    flex-direction: column;
    margin: 4vw;
  }
  .login-view > * {
    margin-bottom: 3vh !important;
  }
}
@media screen and (min-width: 601px) {
  .blog-inner {
    display: grid;
    grid-template-rows: 20vh 45vh;
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
    margin-bottom: 1vh;
  }
  .blog-top {
    display: inline-flex;
    justify-content: space-between;
  }
  .blog-bottom {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .blog-image {
    max-width: 50vw;
    height: 66.66%;
  }
  .article-content {
    margin: 1vw;
    margin-bottom: 5vh;
  }
  .login-view {
    display: flex;
    flex-direction: column;
    margin: 2vw;
  }
  .login-view > * {
    margin-bottom: 3vh !important;
  }
  .title-flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media screen and (min-width: 601px) and (max-width: 800px) {
  .blog-inner {
    display: grid;
    grid-template-rows: 16vh 45vh;
    width: 100%;
    border-bottom: 1px solid #f5f5f5;
    margin-bottom: 1vh;
  }
  .blog-top {
    display: inline-flex;
    justify-content: space-between;
  }
  .blog-bottom {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .blog-image {
    max-width: 50%;
    height: 75%;
  }
  .login-view > * {
    margin-bottom: 3vh !important;
  }
}
.article-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.article-content p a {
  font-weight: 600;
  cursor: pointer;
}
.article-img {
  opacity: 0;
}
