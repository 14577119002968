@media screen and (min-width: 601px) {
  .eco {
    display: grid;
    grid-template-rows: 100vh;
    max-width: 100%;
  }
  .edesc {
    margin: 3vw;
  }
  .ecommerce {
    display: grid;
    grid-template-rows: 7vh 85vh;
    max-width: 100%;
  }
  .navgrid {
    display: grid;
    grid-template-columns: 7vw auto 7vw;
    width: 100%;
    border: 1px solid #146396;
    color: white;
    background-color: #146396;
  }
  .itemview {
    -webkit-columns: 3;
    -moz-columns: 3;
    columns: 3;
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
  }
  .item {
    width: 100%;
  }
  .item:hover {
    cursor: pointer;
  }
  .itemlayout {
    display: grid;
    grid-template-columns: 90vw;
    height: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;
  }
  .itemlayout::-webkit-scrollbar {
    display: none;
  }
  .invis {
    visibility: hidden;
  }
  .vis {
    visibility: visible;
  }
  .ecom-title {
    display: flex;
    height: 100%;
    align-items: center;
  }
}
@media screen and (max-width: 600px) {
  .itemlayout {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;
  }
  .itemview {
    -webkit-columns: 2;
    -moz-columns: 2;
    columns: 2;
    width: 100%;
    height: fit-content;
    background-color: #f5f5f5;
  }
  .eco {
    display: grid;
    grid-template-rows: 100%;
    max-width: 100%;
    height: 100%;
  }
  .edesc {
    margin: 3vw;
  }
  .ecommerce {
    display: grid;
    grid-template-rows: 7vh 73vh;
    max-width: 100%;
  }
  .navgrid {
    display: grid;
    grid-template-columns: 7vw auto 7vw;
    width: 100%;
    border: 1px solid #146396;
    color: white;
    background-color: #146396;
  }
  .item {
    width: 100%;
  }
  .item:hover {
    cursor: pointer;
  }
  .itemlayout {
    display: grid;
    grid-template-columns: 100%;
    overflow-y: scroll;
    -ms-overflow-style: none;
  }
  .itemlayout::-webkit-scrollbar {
    display: none;
  }
  .invis {
    visibility: hidden;
  }
  .vis {
    visibility: visible;
  }
}
.MuiMenu-list {
  display: flex;
}
