@media screen and (max-width: 600px) {
  .intro-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .intro-text {
    width: 100%;
    height: 40vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .intro-grid {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .intro-grid img {
    max-width: 100%;
  }
  .icon-adjust {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .git-flex {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
  .hide-photo {
    z-index: 0;
  }
}
@media screen and (min-width: 601px) {
  .intro-container {
    display: flex;
    flex-direction: column;
    margin-left: 5vw;
    margin-bottom: 6vh;
  }
  .intro-text {
    display: grid;
    grid-template-columns: 30vw 60vw;
    grid-template-rows: 40vh;
  }
  .intro-grid {
    display: flex;
    width: 100%;
    height: 100%;
  }
  .intro-photo {
    opacity: 0;
  }
  .intro-grid img {
    object-fit: cover;
    width: 50vw;
  }
  .intro-center {
    align-self: center;
  }
  .icon-adjust {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
  }
  .git-flex {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
}
