@media screen and (min-width: 1001px) {
  .trail-container {
    display: grid;
    grid-template-rows: 20vh 80vh;
    height: 100%;
  }
  .search {
    display: grid;
    grid-template-rows: 10vh 10vh;
  }
  .results {
    overflow-y: scroll;
    width: 100%;
    -ms-overflow-style: none;
    height: 100%;
  }
  .results::-webkit-scrollbar {
    display: none;
  }
  .result-flex {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .trail-item {
    display: flex;
    height: 40vh;
    width: 100%;
  }
  .trail-detail {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    margin-left: 1vw;
    width: 100%;
  }
  .trail-item img {
    object-fit: cover;
    width: 40vw;
  }
}
@media screen and (min-width: 601px) and (max-width: 1000px) {
  .trail-container {
    display: grid;
    grid-template-rows: 20vh 80vh;
    height: 100%;
    width: 100%;
  }
  .search {
    display: grid;
    grid-template-rows: 10vh 10vh;
    width: 99%;
  }
  .results {
    overflow-y: scroll;
    width: 100%;
    -ms-overflow-style: none;
    height: 100%;
  }
  .results::-webkit-scrollbar {
    display: none;
  }
  .result-flex {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .trail-item {
    display: flex;
    height: 30vh;
    width: 100%;
    opacity: 1 !important;
  }
  .trail-detail {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    margin-left: 1vw;
    width: 100%;
  }
  .trail-item img {
    object-fit: cover;
    width: 45vw;
  }
}
@media screen and (max-width: 600px) {
  .trail-item img {
    object-fit: cover;
    width: 50vw;
    height: 95%;
  }
  .trail-item {
    display: grid;
    grid-template-columns: 50vw 50vw;
    width: 100%;
    opacity: 1 !important;
  }
  .trail-container {
    display: grid;
    grid-template-rows: 20vh 80vh;
    height: 100%;
    width: 100%;
  }
  .search {
    display: grid;
    grid-template-rows: 10vh 10vh;
    width: 99%;
  }
  .results {
    overflow-y: scroll;
    width: 100%;
    -ms-overflow-style: none;
    height: 100%;
  }
  .results::-webkit-scrollbar {
    display: none;
  }
  .result-flex {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
  }
  .trail-summary {
    font-size: 1.8vh;
  }
  .trail-name {
    font-size: 1.9vh;
  }
  .trail-detail {
    display: grid;
    grid-template-rows: 1fr 1fr 1fr;
    margin-left: 1vw;
    width: 100%;
  }
}
