@media screen and (max-width: 600px) {
  .view-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
  }
  .img-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    max-width: 100%;
    overflow-x: scroll;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  .img-group img:hover {
    cursor: pointer;
  }
  .img-group img {
    width: 33%;
    max-width: 33%;
    height: 33%;
  }
  h3 {
    align-self: center;
  }
  .select-style {
    width: 100%;
  }
}
@media screen and (min-width: 601px) {
  .view-container {
    display: grid;
    grid-template-columns: 20vw 45vw 25vw;
    height: 100%;
    width: 100%;
    background-color: #f5f5f5;
  }
  .img-group {
    display: flex;
    flex-direction: column;
  }
  img {
    max-width: 100%;
  }
  .img-group img:hover {
    cursor: pointer;
  }
  .options {
    display: flex;
    width: 100%;
    height: 50%;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .MuiInputBase-root {
    width: 100%;
  }
  .MuiInputBase {
    width: 100%;
  }
  .options h3 {
    text-align: center;
  }
}
