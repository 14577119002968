@media screen and (max-width: 600px) {
  .container {
    width: 100vw;
    display: flex;
    flex-direction: column;
  }
  .section {
    height: 100vh;
    margin: 1vw;
    z-index: 2;
    color: #146396;
    width: 100vw;
    background-color: white;
  }
  .section:nth-child(1) {
    height: 90vh;
  }
  .gap-top {
    margin-top: 10vh;
  }
  .text-section {
    height: 50vh !important;
  }
  .shortened-section {
    height: 80vh !important;
  }
}
@media screen and (min-width: 601px) {
  .container {
    width: max-content;
    height: 100vh;
    display: flex;
    flex-wrap: nowrap;
    -ms-overflow-style: none;
  }
  .container:-webkit-scrollbar {
    display: none;
  }
  .section {
    width: 90vw;
    margin: 1vw;
    z-index: 2;
    color: #146396;
  }
}
/* background: -webkit-linear-gradient(to bottom, #f5f5f5 50%, white 50%);
    background: -moz-linear-gradient(to bottom, #f5f5f5 50%, white 50%);
    background: -ms-linear-gradient(to bottom, #f5f5f5 50%, white 50%);
    background: linear-gradient(to bottom, #f5f5f5 50%, white 50%);*/
.MuiFormControl-root {
  background-color: #f5f5f5;
  border-color: #146396;
}
.MuiOutlineInput-root.Mui-focused {
  border-color: #146396 !important;
}
.MuiFormLabel-root.Mui-focused {
  color: #146396 !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #146396 !important;
}
