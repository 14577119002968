@media screen and (max-width: 600px) {
  .scroll-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .scroll-text {
    height: 40vh;
    width: 90vw;
    display: flex;
    justify-content: center;
    line-height: 210%;
  }
  .scroll-text h3 {
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    max-width: 36em;
  }
}
@media screen and (min-width: 601px) {
  .scroll-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
  .scroll-text {
    height: 40vh;
    width: 70%;
    display: flex;
    justify-content: center;
    line-height: 210%;
  }
  .scroll-text h3 {
    text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
    max-width: 36em;
  }
}
